<template>
    <div class="account-content">
        <div class="settings">
            <div class="settings-top">
                <div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
                    <a href="javascript:void(0)" class="account-back d-flex align-items-center mr-4" @click="$emit('clearEditProduct')">
                        <i class="fas fa-chevron-left"></i>
                        <div class="account-back-text">Назад</div>
                    </a>
                    <div class="settings-title">{{activeProduct.name}}</div>
                </div>
            </div>
        </div>
        <div class="school-cabinet">
            <div class="school-cabinet_white-box">
                <div class="school-cabinet_info-form school-cabinet_info-form--full">
                    <div class="school-cabinet_info-row-title">
                    Связать поток
                    </div>
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                        <DropDownMenuAHK
                            :list="groups"
                            :active="activeProduct.ask_group"
                            @selected="setGroup"
                            @reset="resetGroup"
                            @search="search"
                        />

                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                        <DropDownMenuAMO :list="categories" :active="activeProduct.amocrm_category" @selected="setAmoCRM" />
                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                        <button class="btn btn-blue btn-full" @click="$emit('save')" v-if="!loading">Сохранить</button>
                        <button class="btn btn-blue btn-full" v-else>Сохраняем...</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DropDownMenuAHK from '@/components/DropDownMenuAHK.vue'
import DropDownMenuAMO from '@/components/DropDownMenuAMO.vue'
export default {
    name: 'ProductEdit',
	components: {
		DropDownMenuAHK,
		DropDownMenuAMO,
	},
    props: {
        activeProduct: {
            type: Object,
        },
        loading: {
            type: Boolean,
        },
    },
    methods: {
		...mapActions('groups', {
			clearGroups: 'clear',
			setGroupsQuery: 'setQuery',
			getGroups: 'getList',
		}),
        resetGroup() {
            this.$emit('setGroup', null);
        },
        setGroup(val) {
            this.$emit('setGroup', val);
        },
        setAmoCRM(val) {
            this.$emit('setAmoCRM', val);
        },
        search(value) {
            this.clearGroups();
            this.setGroupsQuery(value);
            this.getGroups();
        },
    },
	computed: {
		...mapGetters('groups', {
			groups: 'list',
		}),
		...mapGetters('categories', {
			categories: 'list',
		}),
	},
}
</script>