<template>
    <div class="account-content">
        <div class="settings">
            <div class="settings-top">
                <div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
                    <a href="javascript:void(0)" class="account-back d-flex align-items-center mr-4" @click="$emit('clearEditProduct')">
                        <i class="fas fa-chevron-left"></i>
                        <div class="account-back-text">Назад</div>
                    </a>
                    <div class="settings-title">{{activeProduct.name}}</div>
                </div>
            </div>
        </div>
        <div class="school-cabinet">
            <div class="school-cabinet_white-box">
                <div class="school-cabinet_info-form school-cabinet_info-form--full">
                    <div class="school-cabinet_info-row-title">
                    Связать поток
                    </div>
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                        <input type="text" readonly :value="activeProduct.name" class="form-control form-control--bordered" disabled >
                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                        <DropDownMenuServices :list="services" :active="activeProduct.ask_service" @selected="setService" />
                    </div>
                    <div class="school-cabinet_info-field school-cabinet_info-field--small">
                        <button class="btn btn-blue btn-full" @click="$emit('save')" v-if="!loading">Сохранить</button>
                        <button class="btn btn-blue btn-full" v-else>Сохраняем...</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDownMenuServices from '@/components/DropDownMenuServices.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'ProductEdit',
    components: {
        DropDownMenuServices
    },
    props: {
        activeProduct: {
            type: Object,
        },
        loading: {
            type: Boolean,
        },
    },
    methods: {
        setService(val) {
            this.$emit('setService', val);
        },
    },
	computed: {
		...mapGetters('services', {
			services: 'list',
		}),
		...mapGetters('categories', {
			categories: 'list',
		}),
	},
}
</script>