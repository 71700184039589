import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    page: 1,
  },

  getters: {
    list: state => {
      return state.list;
    },
    meta: state => {
      return state.meta;
    },
  },

  actions: {
    async getList({commit, state}) {
      let list = await axios.get(`${settings.APP_HUB_URL}rest/ask/service?page=${state.page}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_LIST', list.data)
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.list.push(...list.data);
      state.meta = list.meta;
      state.page++;
    },
  },
};
