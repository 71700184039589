import axios from 'axios'
import settings from '@/settings.js'

export default {
  namespaced: true,
  state: {
    token: null,
  },

  getters: {
    
  },

  actions: {
    async getToken({commit}, token) {
      let jwt = await axios.get(`${settings.APP_URL}token`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_TOKEN', jwt)
    },
  },

  mutations: {
    SET_TOKEN(state, jwt) {
      state.token = jwt.data['jwt-token'];
      localStorage.setItem('token', state.token);
    },
  },
};
