<template>
    <div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false">
        <div class="settings">
            <div class="settings-top">
                <div class="settings-head settings-head--exams d-flex align-items-center flex-wrap">
                    <router-link to="/" class="account-back d-flex align-items-center mr-4">
                        <i class="fas fa-chevron-left"></i>
                        <div class="account-back-text">Назад</div>
                    </router-link>
                    <div class="settings-title">Тарифы</div>
                </div>
            </div>
        </div>
        <div class="school-cabinet_table school-cabinet_table--integrations">
            <div class="school-cabinet_table-title school-cabinet_table-title--1">Тариф</div>
            <div class="school-cabinet_table-title school-cabinet_table-title--2">Услуга</div>
        </div>
        <div class="school-cabinet_card school-cabinet_card--integrations" v-for="tariff in tariffs" :key="tariff.id">
            <div class="school-cabinet_card-info d-flex align-items-center">
                <div class="school-cabinet_card-info-item school-cabinet_card-info-item--course">
                <div class="school-cabinet_card-info-title">
                    <strong>{{tariff.name}}</strong>
                </div>
                </div>
                <div class="school-cabinet_card-info-item school-cabinet_card-info-item--cat">
                <div class="school-cabinet_card-info-title">
                    <strong v-if="tariff.ask_service">{{ tariff.ask_service.name}}</strong>
                    <strong v-else>-</strong>
                </div>
                </div>
                <div class="school-cabinet_card-edit ml-auto" @click="$emit('edit', tariff)"></div>
            </div>
        </div>
        <div class="custom-paginate">
            <pagination v-model="page" v-if="meta.total" :per-page="meta.per_page" :records="meta.total" @paginate="cahngePage" :options="options" />
        </div>
    </div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'ProductList',
    data() {
        return {
            page: 1,
            options: {
                edgeNavigation: false,
                texts: {
                    count: ''
                }
            },
        }
    },
    components: {
        Pagination,
    },
	computed: {
		...mapGetters('tariffs', {
			tariffs: 'list',
			meta: 'meta',
		}),
	},
    mounted() {
        this.getTariffs(this.page);
    },
    methods: {
        ...mapActions('tariffs', {
            getTariffs: 'getList',
        }),
        cahngePage() {
            this.getTariffs(this.page);
        }
    }
}
</script>