<template>
  <div class="page">
    <div class="d-flex">
        <div class="settings w-100">
            <div class="settings-top d-flex align-items-center justify-content-between w-100">
                <div class="settings-head d-flex align-items-center">
                <div class="settings-title">Интеграции</div>  
                </div>
                 <router-link to="/tariffs" class="btn btn-blue btn-medium ml-auto mr-2 mt-2">Тарифы</router-link>
            </div>   
        </div>
    </div>
    <Service />
  </div>
</template>

<script>
  import Service from '@/components/Service'
  export default {
    name: "Main",
    components: {
      Service,
    }
  }
</script>