<template>
	<div class="service">
		<div class="school-cabinet">
			<div class="school-cabinet_wrapper" v-if="editId == 0">
				<TariffList @edit="editProduct" />
			</div>
			<div v-else>
				<TariffEdit @setService="setService" @save="save" @clearEditProduct="clearEditProduct" :activeProduct="activeProduct" :loading="loading" />
			</div>
		</div>
	</div>
</template>

<script>
  import TariffList from '@/components/TariffList.vue'
  import TariffEdit from '@/components/TariffEdit.vue'
  import { mapGetters, mapActions } from 'vuex';
  export default {
	name: "Tariffs",
	components: {
		TariffList,
		TariffEdit,
	},
	data() {
		return {
			editId: 0,
			service: null,
			name: null,
			group: null,
			loading: false,
		}
	},
	methods: {
		...mapActions('tariffs', {
			saveTariff: 'save',
			getProducts: 'getList',
		}),
		setService(val) {
			this.service = val;
		},
		editProduct(tariff) {
			this.editId = tariff.id;
			this.service = tariff.ask_service;
			this.name = tariff.name;
		},
		clearEditProduct() {
			this.editId = 0;
			this.amo = null;
			this.group = null;
		},
		save() {
			this.loading = true;
			if (this.service) {
				let payload = {
					payload: {
						"tariff": {
							"ask_service_id": this.service.id,
						}
					},
					id: this.activeProduct.id,
				}
				console.log(payload);
				this.saveTariff(payload).then(() => {
					this.editId = 0;
					this.service = null;
					this.loading = false;
				}).catch((e) => {
					this.$toast.error(e)
				})
			} else {
				this.$toast.error('Необходимо заполнить все поля')
			}
		}
	},
	computed: {
		...mapGetters('products', {
			products: 'list',
		}),
		...mapGetters('groups', {
			groups: 'list',
		}),
		...mapGetters('categories', {
			categories: 'list',
		}),
		...mapGetters('tariffs', {
			tariffs: 'list',
		}),
		activeProduct() {
			return this.tariffs.find(p => p.id == this.editId);
		},
	},
  }
</script>