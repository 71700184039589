import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    filters: {
      page: 1,
      query: '',
    }
  },

  getters: {
    list: state => {
      return state.list;
    },
    meta: state => {
      return state.meta;
    },
  },

  actions: {
    async getList({commit, state}) {
      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));
      let list = await axios.get(`${settings.APP_HUB_URL}rest/ask/groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      });
      commit('SET_LIST', list.data)
    },
    clear({commit}) {
      commit('CLEAR')
    },
    setQuery({commit}, query) {
      commit('SET_QUERY', query)
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.list.push(...list.data);
      state.meta = list.meta;
      state.filters.page++;
    },
    CLEAR(state) {
      state.list = [];
      state.meta = {};
      state.filters.page = 1;
    },
    SET_QUERY(state, query) {
      state.filters.query = query;
    },
  },
};
