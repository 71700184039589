<template>
    <div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false">
        <div class="school-cabinet_table school-cabinet_table--integrations">
            <div class="school-cabinet_table-title school-cabinet_table-title--1">Поток</div>
            <div class="school-cabinet_table-title school-cabinet_table-title--2">Категория в AmoCRM</div>
            <div class="school-cabinet_table-title school-cabinet_table-title--3">Группа в АШК</div>
        </div>
        <div class="school-cabinet_card school-cabinet_card--integrations" v-for="product in products" :key="product.id">
            <div class="school-cabinet_card-info d-flex align-items-center">
                <div class="school-cabinet_card-info-item school-cabinet_card-info-item--course">
                <div class="school-cabinet_card-info-title">
                    <strong>{{product.name}}</strong>
                </div>
                </div>
                <div class="school-cabinet_card-info-item school-cabinet_card-info-item--cat">
                <div class="school-cabinet_card-info-title">
                    <strong v-if="product.amocrm_category">{{ product.amocrm_category.name }}</strong>
                    <strong v-else>-</strong>
                </div>
                </div>
                <div class="school-cabinet_card-info-item school-cabinet_card-info-item--group">
                <div class="school-cabinet_card-info-title">
                    <strong v-if="product.ask_group">{{ product.ask_group.name }}</strong>
                    <strong v-else>-</strong>
                </div>
                </div>
                <div class="school-cabinet_card-edit ml-auto" @click="$emit('edit', product)"></div>
            </div>
        </div>
        <div class="custom-paginate">
            <pagination v-model="page" v-if="meta.total" :per-page="meta.per_page" :records="meta.total" @paginate="cahngePage" :options="options" />
        </div>
    </div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'ProductList',
    data() {
        return {
            page: 1,
            options: {
                edgeNavigation: false,
                texts: {
                    count: ''
                }
            },
        }
    },
    components: {
        Pagination,
    },
    mounted() {
        this.getProducts(this.page);
    },
	computed: {
		...mapGetters('products', {
			products: 'list',
			meta: 'meta',
		}),
	},
    methods: {
        ...mapActions('products', {
            getProducts: 'getList',
        }),
        cahngePage() {
            this.getProducts(this.page);
        }
    }
}
</script>

<style>
    .custom-paginate {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
</style>