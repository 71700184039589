import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import './assets/css/main.css'
Vue.use(VueToast);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app2')
