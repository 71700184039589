<template>
  <div class="page">
    <Service />
  </div>
</template>
<script>
import Service from '@/components/Tariffs.vue'
import { mapActions } from 'vuex';
export default {
  name: 'Tariffs',
  components: {
    Service,
  },
  mounted() {
    this.getServices();
  },
  methods: {
    ...mapActions('services', {
      getServices: 'getList',
    })
  }
}
</script>