<template>
    <div class="drop-down-menu">
      <div
        v-click-outside="hide"
        @click="opened = !opened"
        :class="{ 'border-bottom-none': opened}"
        class="form-control form-control--bordered"
      >
        <template v-if="selected">{{ selected.name }}</template>
        <template v-else>Категория в AmoCRM</template>
  
        <span
          :class="{ rotate: opened }"
          class="form-control--arrow"
        ></span>
      </div>
  
      <ul v-if="opened" class="form-control--custom">
        <li
          @click="select(null)"
        >
          Не выбрано
        </li>
        <li
          v-for="(li, index) in list"
          :key="index"
          @click="select(li)"
        >
          {{ li.name }}
        </li>
        <infinite-loading @infinite="infiniteHandler">
          <span slot="no-more"></span>
          <span slot="no-results"></span>
        </infinite-loading>
      </ul>
    </div>
  </template>
  
  <script>
  import ClickOutside from 'vue-click-outside';
  import { mapActions } from 'vuex';
  import InfiniteLoading from 'vue-infinite-loading';
  
  export default {
    name: 'DropDownMenuAHK',
  
    props: {
      list: {
        typeof: Array,
        required: true
      },
      active: {
        typeof: Object,
        required: false,
        default: null,
      }
    },
  
    directives: {
      ClickOutside
    },
  
    data() {
      return {
        opened: false,
        selected: ''
      }
    },

    components: {
      InfiniteLoading,
    },
  
    created() {
        if (this.active) {
            this.selected = this.list.find(l => l.id == this.active.id);
        }
    },
  
    mounted () {
      this.popupItem = this.$el
    },
  
    methods: {
      ...mapActions('categories', {
        getCategories: 'getList',
      }),
      hide() {
        this.opened = false;
      },
      select(li) {
        this.selected = li;
  
        this.hide();
  
        this.$emit('selected', li);
      },
      infiniteHandler($state) {
        let count = this.list.length;
        this.getCategories().then(() => {
          if (this.list.length == count) {
            $state.complete();
          } else {
            $state.loaded();
          }
        })
      }
    }
  }
  </script>
  