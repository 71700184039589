import axios from 'axios'
import settings from '@/settings.js'
let token = localStorage.getItem('token');

export default {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    page: 1,
  },

  getters: {
    list: state => {
      return state.list;
    },
    meta: state => {
      return state.meta;
    },
  },

  actions: {
    async getList({commit}, page = 1) {
      let list = await axios.get(`${settings.APP_HUB_URL}rest/product?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      commit('SET_LIST', list.data)
    },
    /* eslint-disable no-empty-pattern */
    async save({ }, payload) {
      await axios.patch(`${settings.APP_HUB_URL}rest/product/${payload.id}`, payload.payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.page++;
      state.list  = list.data;
      state.meta  = list.meta;
    },
  },
};
