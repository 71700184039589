import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import products from './modules/products';
import categories from './modules/categories';
import services from './modules/services';
import groups from './modules/group';
import tariffs from './modules/tariff';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    products,
    categories,
    services,
    groups,
    tariffs,
  },
});
